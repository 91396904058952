import axios from 'axios';
import { SIMPLE_WINE_API_URL } from '../../config';
import { GET_COUNTRIES } from './type';

export const getCountries = () => {
  return async dispatch => {
    try {
      const { data } = await axios.get(`${SIMPLE_WINE_API_URL}/countries`);
      if (data.success) {
        const countries = data.data.map(country => {
          return {
            value: country.id,
            label: country.name
          }
        });
        dispatch({
          type: GET_COUNTRIES,
          data: countries
        });
      }
    } catch (e) {
      dispatch({
        type: 'ERROR',
        error: e
      });
    }
  }
}