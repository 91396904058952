import { LOADING, GET_LOGS, GET_FILTERS } from '../actions/logs/type';

const initialState = {
  logs: [],
  filters: null,
  loading: false,
  error: null
};

const wineReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: true
      };
    case GET_LOGS:
      return {
        ...state,
        logs: action.data,
        loading: false
      };
    case GET_FILTERS:      
      return {
        ...state,
        filters: action.data,
        loading: false
      };
    default:
      return state;
  }
}

export default wineReducer;