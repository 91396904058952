import React from "react";
import './Home.scss';
import ListWines from "../../components/Wines/ListWines";


const Home = (props) => {
	return (
		<div className="main-content d-flex">
			<ListWines />
		</div>
	)
}

export default Home;