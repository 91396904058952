import { GET_COUNTRIES } from '../actions/countries/type';

const initialState = {
  countries: [],
  error: null
};

const countryReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COUNTRIES:
      return {
        ...state,
        countries: action.data
      };
    case 'ERROR':
      return {
        ...state,
        error: action.error
      }
    default:
      return state;
  }
}

export default countryReducer;