import React, { useState } from "react";
import { connect } from 'react-redux';
import './Search.scss';
import { getSearchResults } from '../../actions/search';
import { getWines } from '../../actions/wines';

const Search = (props) => {
  const [search, setSearch] = useState('');
  const [timer, setTimer] = useState(null);
  const { type, getSearchResults, getWines } = props;

  const handleInputChange = e => {
    e.preventDefault();
    const { value } = e.target;
    setSearch(value);
    clearTimeout(timer);
    if (value === '') {
      return getWines();
    } else {
      setTimer(setTimeout(() => {
        getSearchResults({
          value: value,
          type
        });
      }, 350));
    }
  }

  return (
    <div className="search">
      <input
        placeholder="Rechercher un vin ..."
        type="text"
        value={search}
        onChange={e => handleInputChange(e)}
      />
    </div>
  )
}

export default connect(null, {
  getSearchResults,
  getWines
})(Search);