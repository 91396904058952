import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import Home from "./pages/Home/Home";
import Logs from "./pages/Logs";
import Wine from "./pages/Wine/Wine";
import Login from './pages/Login/Login';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import routes from './routes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getCountries } from './actions/countries';
import Sidenav from "./components/Sidenav/Sidenav";

const App = (props) => {
  const successToast = message => toast.success(message);
  const errorToast = message => toast.error(message);
  const { message, type, getCountries } = props;

  if (type !== null) {
    switch (type) {
      case 'success':
        successToast(message);
        break;
      case 'error':
        errorToast(message);
        break;
      default:
        successToast(message);
    }
  }

  useEffect(() => {
    getCountries();
  }, [getCountries])

  return (
    <>
      <ToastContainer />
      <Router>
        <Sidenav />
        <Switch>
          <Route exact path={routes.login} component={Login} />
          <PrivateRoute exact path={routes.home} component={Home} />
          <PrivateRoute exact path={routes.wine} component={Wine} />
          <PrivateRoute exact path={routes.logs} component={Logs} />
        </Switch>
      </Router>
    </>
  );
}

const mapStateToProps = ({ notificationReducer }) => {
  return {
    message: notificationReducer.message,
    type: notificationReducer.type,
  }
};

export default connect(mapStateToProps, {
  getCountries
})(App);