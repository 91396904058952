import React, { useState } from "react";
import { connect } from 'react-redux';
import './Parking.scss';
import { dropParking } from '../../actions/notes';
import ItemNote from "../Notes/ItemNote";

const Dice = (props) => {
  const [isFocus, setIsFocus] = useState(false);
  let { face, position, dropParking, notesWithoutOrder } = props;
  const style = {
    float: position !== undefined ? position : 'none',
    backgroundColor: isFocus ? '#EF4136' : 'transparent',
    color: isFocus ? 'white' : 'black'
  }

  const allowDrop = (e) => {
    e.preventDefault();
  }

  const drop = (e) => {
    e.preventDefault();
    const note = JSON.parse(e.dataTransfer.getData('Text'));
    if (note.order !== null) {
      dropParking(note, {
        order: null
      });
    }
    exit(e);
  }

  const enter = (e) => {
    setIsFocus(true);
  }

  const exit = (e) => {
    setIsFocus(false);
  }

  return (
    <div
      style={style}
      className={`parking align-center`}
      onDrop={e => {
        e.persist();
        return drop(e);
      }}
      onDragOver={e => allowDrop(e)}
      onDragEnter={e => enter(e)}
      onDragLeave={e => exit(e)}
    >
      {
      
        notesWithoutOrder !== null
          ? notesWithoutOrder.map(note =>
            <ItemNote
              key={note.id}
              note={note}
            />
          )
          : null
      }
    </div>
  )
}

export default connect(null, {
  dropParking
})(Dice);