import React, { useState, useEffect } from 'react';
import Dice from '../Dice';
import './Dices.scss';

const Dices = (props) => {
  const { notes } = props;
  const [note1, setNote1] = useState(null);
  const [note2, setNote2] = useState(null);
  const [note3, setNote3] = useState(null);
  const [note4, setNote4] = useState(null);
  const [note5, setNote5] = useState(null);

  useEffect(() => {
    for (let i = 0; i < notes.length; i++) {
      const order = notes[i].order;
      if (order === 1) {
        setNote1(notes[i]);
      }
      if (order === 2) {
        setNote2(notes[i]);
      }
      if (order === 3) {
        setNote3(notes[i]);
      }
      if (order === 4) {
        setNote4(notes[i]);
      }
      if (order === 5) {
        setNote5(notes[i]);
      }
    }

    return () => {
      setNote1(null);
      setNote2(null);
      setNote3(null);
      setNote4(null);
      setNote5(null);
    }
  }, [notes]);

  return (
    <div className="dices d-flex align-center">
      <div className="dices-container">
        <div className="dices-row">
          <Dice face="2" position='left' note={note2} />
          <Dice face="3" position='right' note={note3} />
        </div>
        <div className="dices-row middle-row">
          <Dice face="1" note={note1} />
        </div>
        <div className="dices-row">
          <Dice face="4" position='left' note={note4} />
          <Dice face="5" position='right' note={note5} />
        </div>
      </div>
    </div>
  )
}

export default Dices;