import { NOTE_ADDED, NOTE_UPDATED } from '../actions/notes/type';

const initialState = {
  note: null,
  openModal: false
};

const noteReducer = (state = initialState, action) => {
  switch (action.type) {
    case NOTE_ADDED:
      return {
        ...state,
        note: action.data,
        openModal: true
      };
    case NOTE_UPDATED:
      return {
        ...state,
        note: action.data,
        openModal: false
      };
    default:
      return state;
  }
}

export default noteReducer;