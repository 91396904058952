import React, { useEffect } from "react";
import { connect } from 'react-redux';
import './Wine.scss';
import ManageWine from "../../components/Wines/ManageWine";
import ListNotes from "../../components/Notes/ListNotes";
import { getCurrentWine } from '../../actions/wines';

const Wine = (props) => {
	const { wine, getCurrentWine } = props;

	useEffect(() => {
		getCurrentWine(props.match.params.id);
	}, []);

	if (wine === null) {
		return (
			<div className="main-content">
				<div className="wine">
					<div>Aucun vin trouvé</div>
				</div>
			</div>
		)
	}

	return (
		<div className="main-content">
			<div className="wine">
				<ManageWine wine={wine} />
				<ListNotes />
			</div>
		</div>
	)
}

const mapStateToProps = ({ wineReducer }) => {
	return {
		wine: wineReducer.wine
	}
};

export default connect(mapStateToProps, {
	getCurrentWine
})(Wine);