import axios from 'axios';
import { SIMPLE_WINE_API_URL } from '../../config';
import { errorNotif, initNotif } from '../notifications';
import { GET_WINES } from "../wines/type";

export function getSearchResults({ value, type }) {
  return async dispatch => {
    try {
      const { data } = await axios.get(`${SIMPLE_WINE_API_URL}/search/${type}/${value}`);

      if (type === 'wine') {
        dispatch({
          type: GET_WINES,
          data: data.data
        });
      }
    } catch (e) {
      dispatch(errorNotif(e.message));
      dispatch(initNotif());
    }
  }
}