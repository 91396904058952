import React from 'react';
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import { connect } from 'react-redux';
import routes from '../../routes';

const PrivateRoute = ({ component: Component, ...props }) => {
	const { authenticated } = props;

	return (
		<Route {...props}
			render={innerProps =>
				authenticated
					?
					<Component {...innerProps} />
					:
					<Redirect to={routes.login} />
			}
		/>
	);
};

const mapStateToProps = ({ userReducer }) => {
	return {
		authenticated: userReducer.authenticated
	}
};

export default connect(mapStateToProps, null)(PrivateRoute);