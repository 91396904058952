import React, { useState, useEffect, useCallback } from "react";
import { withRouter } from 'react-router-dom';
import Modal from 'react-modal';
import { connect } from 'react-redux';
import Select from 'react-select';
import './Modals.scss';
import { useDropzone } from "react-dropzone";
import Logo from '../../assets/images/icons/up-arrow.png';
import store from '../../store';
import { errorNotif } from "../../actions/notifications";
import { editNote, deleteNote, setNoteBackground } from '../../actions/notes';

const year = new Date().getFullYear();
const options = [];
for (let i = 1990; i < year + 1; i++) {
  options.push({
    value: i,
    label: i
  });
};

Modal.setAppElement('body');

const setInitalState = (note) => {
  return {
    note: note.note,
    vintage: note.vintage,
    active: note.active
  }
}

const NoteModal = (props) => {
  const { note, editNote, deleteNote, error, closeModal, isOpen, setNoteBackground } = props;
  const [form, setForm] = useState({});
  const [background, setBackground] = useState(null);

  useEffect(() => {
    setForm(setInitalState(note));
    return () => {
      setForm({});
    }
  }, [note])

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '30vw',
      textAlign: 'center',
      height: 'auto'
    }
  };

  const handleInputChange = e => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value
    });
  }

  const handleSelectChange = e => {
    setForm({
      ...form,
      vintage: e.value
    });
  }

  const handleSwitchChange = e => {
    setForm({
      ...form,
      active: e
    });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    editNote(note.id, form, note);
  }

  const deleteNoteAndRedirect = () => {
    const confirm = window.confirm('Voulez-vous vraiment supprimer cette note ?');
    if (confirm) {
      deleteNote(note);
      closeModal()
    }
  }

  const onDrop = useCallback(async acceptedFiles => {
    if (acceptedFiles.length > 1) {
      return store.dispatch(errorNotif('Un seul fichier est accepté'));
    }
    try {
      const file = await setNoteBackground(note.id, acceptedFiles[0]);
      setBackground(file.background);
    } catch (e) {
      console.log('erreur', e);
    }
  }, [setNoteBackground, note.id])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal()}
      style={customStyles}
      contentLabel="Note Modal"
    >
      <div className="noteModal">
        <h2>Modifier la note</h2>

        {error !== null
          ? <div>error : {error}</div>
          : null
        }

        <div>
          <button type='submit' className="btn delete" onClick={() => deleteNoteAndRedirect()}>X</button>
        </div>

        <form onSubmit={e => handleSubmit(e)}>
          <div>
            <input
              className="name"
              placeholder="note"
              type="number"
              name="note"
              value={form.note}
              onChange={e => handleInputChange(e)}
            />
          </div>

          <div className="customSelect">
            <Select
              name="vintage"
              placeholder="Vintage"
              options={options}
              value={options.filter(option => option.value === form.vintage)}
              onChange={(e) => handleSelectChange(e)}
            />
          </div>

          <div {...getRootProps()} className="content">
            <input {...getInputProps()} />
            {
              isDragActive
                ? <p>Drop the files here ...</p>
                : <div>
                  <div className="contentBackground">
                    <img src={background !== null
                      ? background
                      : note.background !== null
                        ? note.background
                        : Logo
                    }
                      alt="note background" />
                  </div>
                </div>
            }
          </div>

          <div>
            <button type='submit' className="btn add">Enregistrer</button>
          </div>
        </form>

        <button type="" className="btn cancel" onClick={closeModal()}>Annuler</button>
      </div>
    </Modal>
  )
}

const mapStateToProps = ({ wineReducer }) => {
  return {
    error: wineReducer.error
  };
};

export default connect(mapStateToProps, {
  editNote,
  deleteNote,
  setNoteBackground,
})(withRouter(NoteModal));