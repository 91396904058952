import React, { useState } from "react";
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import './Login.scss';
import routes from '../../routes';
import { getUser } from '../../actions/user';

const Login = (props) => {
	const [form, setForm] = useState({
		login: '',
		password: ''
	});

	const errorToast = message => toast.error(message);

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (form.login === '' || form.password === '') {
			return errorToast('Tous les champs doivent être complétés');
		}

		try {
			await props.getUser({
				password: form.password,
				login: form.login
			});
			props.history.push(routes.home);
		} catch (e) {
			errorToast(e);
		}

	}

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setForm({
			...form,
			[name]: value
		});
	}

	return (
		<div className="main-content d-flex align-center justify-center">
			<div className="main-content-container d-flex align-center justify-center is-card">
				<div className="login-container">
					<h2>Login</h2>

					<form onSubmit={e => handleSubmit(e)}>
						<div className="input-container">
							<input
								name="login"
								type="texte"
								placeholder="Nom d'utilisateur / Email"
								onChange={e => handleInputChange(e)}
							/>
						</div>

						<div className="input-container">
							<input
								name="password"
								type="password"
								placeholder="Mot de passe"
								onChange={e => handleInputChange(e)}
							/>
						</div>

						<button className="btn" type="submit">Connexion</button>
					</form>
				</div>
			</div>
		</div>
	)
}

export default connect(null, {
	getUser
})(Login);