import { combineReducers } from 'redux';
import userReducer from './userReducer';
import wineReducer from './wineReducer';
import countryReducer from './countryReducer';
import notificationReducer from './notificationReducer';
import noteReducer from './noteReducer';
import logReducer from './logReducer';

const reducers = combineReducers({
    userReducer,
    wineReducer,
    countryReducer,
    notificationReducer,
    noteReducer,
    logReducer
});

export default reducers;
