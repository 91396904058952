import React, { useEffect } from "react";
import { withRouter } from 'react-router-dom';
import Modal from 'react-modal';
import './Modals.scss';

const DeleteModal = (props) => {
  Modal.setAppElement('body');
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: '30vw',
      textAlign: 'center'
    }
  };

  const { title, body } = props.content;

  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={props.closeModal()}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <div className="deleteModal">
        <h2>{title}</h2>
        <p>{body}</p>
        <div className="row">
          <button className="btn add" onClick={() => props.deleteItem()}>Valider</button>
          <button className="btn delete" onClick={props.closeModal()}>Annuler</button>
        </div>
      </div>
    </Modal>
  )
}

export default withRouter(DeleteModal);