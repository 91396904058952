import React, { useState, useCallback } from "react";
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import axios from 'axios';
import { connect } from 'react-redux';
import Logo from '../../assets/images/icons/up-arrow.png';
import './Wines.scss';
import DeleteModal from '../Modals/deleteModal';
import { editWine, deleteWine, setWineLabel, addTarget, editTarget, deleteTarget } from '../../actions/wines';
import routes from '../../routes';
import { useDropzone } from "react-dropzone";
import { SIMPLE_WINE_API_URL } from '../../config';
import store from '../../store';
import { errorNotif } from "../../actions/notifications";
import AddEtqReco from './AddEtqReco'

const ManageWines = (props) => {
  const { wine, editWine, countries, deleteWine, setWineLabel, addTarget, editTarget, deleteTarget, loading } = props;
  const [name, setName] = useState('');
  const [label, setLabel] = useState(null);
  const [etqReco, setEtqReco] = useState(null);
  const [width, setWidth] = useState(null);
  const [country, setCountry] = useState(0);
  const [modalStatus, setModalStatus] = useState(false);

  const openModal = () => setModalStatus(true);
  const closeModal = () => setModalStatus(false);

  const updateWine = (body) => {
    editWine({
      id: wine.id,
      body
    });
  };

  const createLog = async (e) => {
    try {
      await axios.post(`${SIMPLE_WINE_API_URL}/logs/wine/${wine.id}`, {
        title: e.target.value
      });
    } catch (e) {
      console.error(e);
    }
  }

  const addToVuforia = () => {
    addTarget(wine);
  };
  const updateToVuforia = () => {
    editTarget(wine);
  }

  const renderVuforiaBloc = () => {
    let disabled = '';
    if (wine.targetId === null) {
      if (wine.width === null || wine.etq_reco === null || wine.name === null || wine.vuforiaName === null) {
        disabled = 'disabled';
      }
      return (
        <p>
          <button className="add" disabled={disabled} onClick={e => addToVuforia()}>
            Ajouter sur vuforia
          </button>
        </p>
      )
    }
    return (
      <p>
        <button className="update" onClick={e => updateToVuforia()}>
          Mettre à jour les données vuforia
        </button>
        <button className="delete" onClick={e => deleteTarget(wine)}>
          Supprimer la target
        </button>
      </p>
    )
  }

  const deleteWineAndRedirect = () => {
    deleteWine(wine.id);
    props.history.push(routes.home);
  }

  const onDrop = useCallback(async acceptedFiles => {
    if (acceptedFiles.length > 1) {
      return store.dispatch(errorNotif('Un seul fichier est accepté'));
    }
    try {
      const file = await setWineLabel(wine.id, acceptedFiles[0]);
      setLabel(file.label);
      setWidth(file.width);
    } catch (e) {
      console.log('erreur', e);
    }
  }, [setWineLabel, wine.id])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <div className="ManageWine">
      <h1>Informations générales</h1>
      <div className="content">

        <div {...getRootProps()}>
          <input {...getInputProps()} />
          {
            isDragActive
              ? <p>Drop the files here ...</p>
              : <div>
                <div className="contentLabel">
                  <img src={label !== null
                    ? label
                    : wine.label !== null
                      ? wine.label
                      : Logo
                  }
                    alt="wine logo" />
                </div>
              </div>
          }
        </div>

        <div className="contentText">
          <div>
            <input
              className="name"
              type="text"
              value={name !== '' ? name : wine.name || ''}
              onChange={e => {
                setName(e.target.value);
                updateWine({ name: e.target.value });
              }}
              onBlur={createLog}
            />
          </div>
          <div className="customSelect">
            <Select
              placeholder="Pays"
              options={countries}
              value={country !== 0
                ? countries.filter(countryEl => countryEl.value === country)
                : countries.filter(countryEl => countryEl.value === wine.CountryId)
              }
              onChange={e => {
                setCountry(Number(e.value));
                updateWine({ CountryId: Number(e.value) });
              }}
            />
          </div>
          <div>
            <p>
              <span>Nom Vuforia : </span>
              {wine.vuforiaName !== null
                ? wine.vuforiaName
                : ''
              }
            </p>
          </div>
          <div>
            <p>
              <span>Width : </span>
              {wine.width !== null
                ? `${wine.width} cm`
                : ''
              }
            </p>
          </div>

          <div className="vuforia">
            {
              loading
                ? <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                : renderVuforiaBloc()
            }
          </div>

        </div>

        <AddEtqReco wine={wine} />
      </div>
      <div className="delete">
        <button onClick={openModal}>Supprimer le vin</button>
        <DeleteModal
          isOpen={modalStatus}
          openmodal={() => openModal}
          closeModal={() => closeModal}
          content={{
            body: 'Voulez-vous vraiment supprimer le vin ?',
            title: 'Supprimer le vin'
          }}
          deleteItem={() => deleteWineAndRedirect()}
        />
      </div>
    </div>
  )
}

const mapStateToProps = ({ countryReducer, wineReducer }) => {
  return {
    countries: countryReducer.countries,
    error: wineReducer.error,
    loading: wineReducer.loading
  }
};

export default connect(mapStateToProps, {
  editWine,
  deleteWine,
  setWineLabel,
  addTarget,
  editTarget,
  deleteTarget
})(withRouter(ManageWines));