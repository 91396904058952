import axios from 'axios';
import { SIMPLE_WINE_API_URL } from '../../config';
import { GET_LOGS, LOADING, GET_FILTERS } from './type';
import { successNotif, errorNotif, initNotif } from '../../actions/notifications';

export const getLogs = () => {
  return async dispatch => {
    try {
      dispatch({ type: LOADING });
      const { data } = await axios.get(`${SIMPLE_WINE_API_URL}/logs`);
      if (!data.success) {
        throw (data);
      }
      dispatch({
        type: GET_LOGS,
        data: data.data
      });
    } catch (e) {
      dispatch(errorNotif(e.message));
      dispatch(initNotif());
    }
  }
}

export const getFilters = () => {
  return async dispatch => {
    try {
      dispatch({ type: LOADING });
      const { data } = await axios.get(`${SIMPLE_WINE_API_URL}/logs/filters`);
      if (!data.success) {
        throw (data);
      }
      const userOptions = data.data.users.map(user => {
        return {
          value: user.userId,
          label: user.userName
        }
      });

      const wineOptions = data.data.wines.map(wine => {
        let label = `${wine.WineId}`
        if (wine.name !== null && wine.name !== '') {
          label += `: ${wine.name}`
        }
        return {
          value: wine.WineId,
          label
        }
      });

      dispatch({
        type: GET_FILTERS,
        data: {
          users: [
            {
              value: '',
              label: 'Selectionnez un utilisateur'
            },
            ...userOptions
          ],
          wines: [
            {
              value: '',
              label: 'Selectionnez un vin'
            },
            ...wineOptions
          ]
        }
      });
    } catch (e) {
      dispatch(errorNotif(e.message));
      dispatch(initNotif());
    }
  }
}

export const getLogsByFilter = (options) => {
  return async dispatch => {
    try {
      let obj = {};
      if (options.wine) {
        obj.wineId = options.wine.value;
      }
      if (options.user) {
        obj.userId = options.user.value;
      }
      if (obj.userId === '' && obj.wineId === '') {
        return dispatch(getLogs());
      }
      dispatch({ type: LOADING });
      const { data } = await axios.post(`${SIMPLE_WINE_API_URL}/logs/filters`, {
        wineId: options.wine && options.wine.value ? options.wine.value : null,
        userId: options.user && options.user.value ? options.user.value : null,
      });
      if (!data.success) {
        throw (data);
      }
      dispatch({
        type: GET_LOGS,
        data: data.data
      });
    } catch (e) {
      dispatch(errorNotif(e.message));
      dispatch(initNotif());
    }
  }
}