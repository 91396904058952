import { FETCH_USER, AUTH_USER, UNAUTH_USER } from '../actions/user/type';

const initialState = {
  authenticated: false,
  user: null,
  errors: null
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_USER:
      return {
        ...state,
        authenticated: true
      };
    case UNAUTH_USER:
      return initialState;
    case FETCH_USER:
      return {
        ...state,
        user: action.user
      };
    default:
      return state;
  }
}

export default userReducer;