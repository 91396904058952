import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import './Logs.scss';
import Select from 'react-select';
import { getLogs, getFilters, getLogsByFilter } from '../../actions/logs';

const Logs = (props) => {
  const { getLogs, logs, getFilters, filters, getLogsByFilter } = props;
  const [options, setOptions] = useState(null);

  useEffect(() => {
    getLogs();
    getFilters();
  }, [getLogs, getFilters])

  const renderLog = log => {
    const date = new Date(log.createdAt);
    const action = log.action.toLowerCase();
    let actionClass = '';
    switch (action) {
      case 'create':
        actionClass = 'green';
        break;
      case 'update':
        actionClass = 'yellow';
        break;
      case 'delete':
        actionClass = 'red';
        break;
      default:
        actionClass = '';
    }
    return (
      <tr>
        <td>{log.id}</td>
        <td>{log.userName}</td>
        <td>
          <span className={`tag ${actionClass}`}>{action}</span>
          {log.message}
        </td>
        <td>{date.toLocaleString()}</td>
      </tr>
    )
  }

  const handleChange = (e, target) => {
    setOptions({
      ...options,
      [target]: e
    });
  }

  useEffect(() => {
    if (options !== null) {
      getLogsByFilter(options);
    }
  }, [getLogsByFilter, options])

  const rendreFilters = () => {
    return (
      <div className="filters">
        <div className="filters-user">
          <Select
            name="user"
            placeholder="Selectionnez un utilisateur"
            options={filters.users}
            onChange={(e) => handleChange(e, 'user')}
          />
        </div>

        <div className="filters-wine">
          <Select
            name="wine"
            placeholder="Selectionnez un vin"
            options={filters.wines}
            onChange={(e) => handleChange(e, 'wine')}
          />
        </div>
      </div >
    )
  }

  return (
    <div className="main-content">
      <div className="Logs">
        <h1>Liste des logs</h1>

        <div>
          {
            filters === null
              ? null
              : rendreFilters()
          }
          {
            logs === null || logs.length === 0
              ? <div className="nodata">Aucune donnée</div>
              : (
                <table id="logs">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>User name</th>
                      <th>Message</th>
                      <th>Date de création</th>
                    </tr>
                  </thead>
                  <tbody>
                    {logs.map(log => renderLog(log))}
                  </tbody>
                </table>
              )
          }
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ logReducer }) => {
  return {
    logs: logReducer.logs,
    filters: logReducer.filters
  }
}

export default connect(mapStateToProps, {
  getLogs,
  getFilters,
  getLogsByFilter
})(Logs);