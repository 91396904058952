import axios from 'axios';
import Cookies from 'js-cookie';
import { SSO_URL } from '../../config'
import { AUTH_USER, UNAUTH_USER, FETCH_USER } from './type';
import setAuthorizationToken from '../../services/authorization';

export const getUser = ({ login, password }) => {
  return async dispatch => {
    try {
      const { data } = await axios.post(`${SSO_URL}/admins/auth/token`, {
        login,
        password
      });

      if (!data.success) {
        throw (data.message);
      }

      Cookies.set('sb_token', data.token, { expires: 7 });
      setAuthorizationToken(data.token);
      dispatch({ type: AUTH_USER });
    } catch (e) {
      throw (e);
    }
  }
};