import React, { useState, useEffect, useCallback } from "react";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Logo from '../../assets/images/icons/up-arrow.png';
import './Wines.scss';
import { setWineEtq } from '../../actions/wines';
import { useDropzone } from "react-dropzone";
import { SIMPLE_WINE_API_URL } from '../../config';
import store from '../../store';
import { errorNotif } from "../../actions/notifications";

const AddEtqReco = (props) => {
  const { wine, setWineEtq } = props;
  const [etqReco, setEtqReco] = useState(null);

  const onDrop = useCallback(async acceptedFiles => {
    if (acceptedFiles.length > 1) {
      return store.dispatch(errorNotif('Un seul fichier est accepté'));
    }
    try {
      const file = await setWineEtq(wine.id, acceptedFiles[0]);
      setEtqReco(file.etq_reco);
    } catch (e) {
      console.log('erreur', e);
    }
  }, [setWineEtq, wine.id])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {
        isDragActive
          ? <p>Drop the files here ...</p>
          : <div>
            <div className="contentLabel">
              <img src={etqReco !== null
                ? etqReco
                : wine.etq_reco !== null
                  ? wine.etq_reco
                  : Logo
              }
                alt="Etiquette Reco" className="etq" />
            </div>
          </div>
      }
    </div>
  )
}

const mapStateToProps = ({ countryReducer, wineReducer }) => {
  return {
    error: wineReducer.error
  }
};

export default connect(mapStateToProps, {
  setWineEtq
})(withRouter(AddEtqReco));