import React from "react";
import './Sidenav.scss';
import { withRouter, Link } from 'react-router-dom';
import Logo from '../../assets/images/logo.png';
import wineIcon from '../../assets/images/icons/wine.png';
import logIcon from '../../assets/images/icons/log.svg';
import routes from '../../routes';

const links = [
	{ path: routes.home, name: 'Simple Wine', icon: wineIcon },
	{ path: routes.logs, name: 'Historique', icon: logIcon },
];

const Sidenav = (props) => {

	return (
		<aside className="sidenav">
			<img className="logo" src={Logo} alt="smartbottle" />

			<ul>
				{links.map((link) =>
					<li key={link.path}>
						<Link to={link.path}>
							{link.icon !== null && link.icon !== undefined
								? <img src={link.icon} alt={link.name} />
								: null
							}
							<span>{link.name}</span>
						</Link>
					</li>
				)}
			</ul>
		</aside>
	)
}

export default withRouter(Sidenav);