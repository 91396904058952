import { GET_WINES, LOADING, GET_WINE, LOADED } from '../actions/wines/type';

const initialState = {
  wine: null,
  wines: [],
  loading: false,
  error: null
};

const wineReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: true
      };
    case LOADED:
      return {
        ...state,
        loading: false
      };
    case GET_WINES:
      return {
        ...state,
        wines: action.data,
        loading: false
      };
    case GET_WINE:
      return {
        ...state,
        wine: action.data,
        loading: false
      };
    default:
      return state;
  }
}

export default wineReducer;