import axios from 'axios';
import { SIMPLE_WINE_API_URL } from '../../config';
import { GET_WINE } from '../wines/type';
import store from '../../store';
import { NOTE_ADDED, NOTE_UPDATED, NOTE_DELETED } from './type';
import { successNotif, errorNotif, initNotif } from '../notifications';

export const addNote = (body = {}) => {
  return async dispatch => {
    try {
      const { data } = await axios.post(`${SIMPLE_WINE_API_URL}/notes`, body);
      let wine = store.getState().wineReducer.wine;
      wine.Notes.notesWithoutOrder = [
        data.data,
        ...wine.Notes.notesWithoutOrder,
      ];
      dispatch({
        type: GET_WINE,
        data: wine
      });
      dispatch({
        type: NOTE_ADDED,
        data: data.data
      });
      dispatch(successNotif(data.message));
      dispatch(initNotif());
    } catch (e) {
      dispatch(errorNotif(e.message));
      dispatch(initNotif());
    }
  }
}

export const editNote = (id, body = {}, note = null) => {
  return async dispatch => {
    try {
      const { data } = await axios.patch(`${SIMPLE_WINE_API_URL}/notes/${id}`, body);
      const wine = store.getState().wineReducer.wine;

      if (note !== null) {
        if (note.order === null) {
          const currentNoteIndex = wine.Notes.notesWithoutOrder.findIndex(note => note.id === id);
          wine.Notes.notesWithoutOrder[currentNoteIndex] = data.data;
          wine.Notes.notesWithoutOrder = [
            ...wine.Notes.notesWithoutOrder
          ];
        } else {
          const currentNoteIndex = wine.Notes.notesWithOrder.findIndex(note => note.id === id);
          wine.Notes.notesWithOrder[currentNoteIndex] = data.data;
          wine.Notes.notesWithOrder = [
            ...wine.Notes.notesWithOrder
          ];
        }
      }

      dispatch({
        type: GET_WINE,
        data: wine
      });
      dispatch({
        type: NOTE_UPDATED,
        data: data.data
      })
      dispatch(successNotif(data.message));
      dispatch(initNotif());
    } catch (e) {
      dispatch(errorNotif(e.message));
      dispatch(initNotif());
    }
  }
}

export const dropNote = (note, body = {}) => {
  return async dispatch => {
    try {
      const { data } = await axios.patch(`${SIMPLE_WINE_API_URL}/notes/${note.id}`, body);
      const wine = store.getState().wineReducer.wine;

      // Vérifie si la note a un champs order pour déterminer si elle est dans le dé
      if (note.order === null) {
        // Permet d'inverser l'ordre des notes dans le dé si la position etait déja prise par une autre note
        if (data.noteWithSameOrder !== null) {
          wine.Notes.notesWithoutOrder = wine.Notes.notesWithoutOrder.filter(item => {
            return item.id !== note.id && item.id !== data.noteWithSameOrder.id
          });
          wine.Notes.notesWithoutOrder = [
            ...wine.Notes.notesWithoutOrder,
            data.noteWithSameOrder
          ];
          wine.Notes.notesWithOrder = [
            ...wine.Notes.notesWithOrder,
            data.data
          ];
        }
        else {
          wine.Notes.notesWithoutOrder = wine.Notes.notesWithoutOrder.filter(item => item.id !== note.id);
          wine.Notes.notesWithOrder = [
            ...wine.Notes.notesWithOrder,
            data.data
          ];
        }
      }
      else {
        if (data.noteWithSameOrder !== null) {
          wine.Notes.notesWithOrder = wine.Notes.notesWithOrder.filter(item => {
            return item.id !== note.id && item.id !== data.noteWithSameOrder.id
          });
          wine.Notes.notesWithOrder = [
            ...wine.Notes.notesWithOrder,
            data.data,
            data.noteWithSameOrder
          ];
        }
        else {
          wine.Notes.notesWithOrder = wine.Notes.notesWithOrder.filter(item => item.id !== note.id);
          wine.Notes.notesWithOrder = [
            ...wine.Notes.notesWithOrder,
            data.data
          ];
        }
      }

      dispatch({
        type: GET_WINE,
        data: wine
      });
      dispatch({
        type: NOTE_UPDATED,
        data: data.data
      })
      dispatch(successNotif(data.message));
      dispatch(initNotif());
    } catch (e) {
      dispatch(errorNotif(e.message));
      dispatch(initNotif());
    }
  }
}

export const dropParking = (note, body = {}) => {
  return async dispatch => {
    try {
      const { data } = await axios.patch(`${SIMPLE_WINE_API_URL}/notes/${note.id}`, body);
      const wine = store.getState().wineReducer.wine;

      if (note.order !== null) {
        wine.Notes.notesWithOrder = wine.Notes.notesWithOrder.filter(item => item.id !== note.id);
        wine.Notes.notesWithoutOrder = [
          ...wine.Notes.notesWithoutOrder,
          data.data
        ];
      }

      dispatch({
        type: GET_WINE,
        data: wine
      });
      dispatch({
        type: NOTE_UPDATED,
        data: data.data
      })
      dispatch(successNotif(data.message));
      dispatch(initNotif());
    } catch (e) {
      dispatch(errorNotif(e.message));
      dispatch(initNotif());
    }
  }
}

export const deleteNote = (note) => {
  return async dispatch => {
    try {
      const { data } = await axios.delete(`${SIMPLE_WINE_API_URL}/notes/${note.id}`);
      if (!data.success) {
        throw (data);
      }
      let wine = store.getState().wineReducer.wine;

      if (note.order === null) {
        wine.Notes.notesWithoutOrder = wine.Notes.notesWithoutOrder.filter(item => item.id !== note.id)
      } else {
        wine.Notes.notesWithOrder = wine.Notes.notesWithOrder.filter(item => item.id !== note.id);
      }

      dispatch({
        type: GET_WINE,
        data: wine
      });
      dispatch({
        type: NOTE_DELETED,
        data: data.data
      });
      dispatch(successNotif(data.message));
      dispatch(initNotif());
    } catch (e) {
      dispatch(errorNotif(e.message));
      dispatch(initNotif());
    }
  }
}

export const setNoteBackground = (id, background) => {
  return async dispatch => {
    try {
      const formData = new FormData();
      formData.append('background', background);
      const { data } = await axios.patch(`${SIMPLE_WINE_API_URL}/notes/${id}/background`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      dispatch({
        type: NOTE_UPDATED,
        data: data.data
      });
      dispatch(successNotif(data.message));
      return data;
    } catch (e) {
      dispatch(errorNotif(e.message));
      dispatch(initNotif());
      return e;
    }
  }
}